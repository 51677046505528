import React from 'react';
import PropTypes from 'prop-types';

const MultipleCheckboxInput = ({
  question,
  id,
  setAnswers,
  answer,
  choices
}) => {
  const calculateAnswersList = (choice) => {
    const newAnswers = [...answer];
    const indexOfChoice = newAnswers.indexOf(choice);
    if (indexOfChoice !== -1) {
      newAnswers.splice(indexOfChoice, 1);
    } else {
      newAnswers.push(choice);
    }
    return newAnswers;
  };

  const handleClick = (questionKey, choice) => {
    const newAnswer = calculateAnswersList(choice);
    setAnswers(previousAnswers => ({
      ...previousAnswers, [questionKey]: newAnswer
    }));
  };

  return (
    <div className="checkbox-input">
      <div className="item-question">{question}</div>
      <div className="choices">
        {choices.map(choice => (
          <label
            htmlFor={choice.key}
            key={choice.key}
            className="choice"
            onClick={() => handleClick(id, choice.key)}
          >
            {choice.text}
            <input
              type="checkbox"
              name={choice.key}
              checked={answer.includes(choice.key)}
              onChange={e => {}}
            />
            <span className="checkbox" />
          </label>
        ))}
      </div>
    </div>
  );
};

MultipleCheckboxInput.propTypes = {
  question: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  answer: PropTypes.arrayOf(PropTypes.string),
  setAnswers: PropTypes.func.isRequired,
  choices: PropTypes.arrayOf(PropTypes.object).isRequired
};

MultipleCheckboxInput.defaultProps = {
  answer: []
};

export default MultipleCheckboxInput;