import React from 'react';
import PropTypes from 'prop-types';
import Locale from '../locale/en/modal';

const Modal = ({ setShowModal }) => {
  const closeModal = () => {
    setShowModal(false);
  };
  const { items } = Locale;
  const renderItems = items.map(item => <li>{item}</li>);
  return (
    <div className="modal">
      <button
        type="button"
        onClick={closeModal}
      >
        X
      </button>
      <h2>{Locale.header}</h2>
      <h3>{Locale.subHeader}</h3>
      <ul>
        {renderItems}
      </ul>
    </div>
  );
};

Modal.propTypes = {
  setShowModal: PropTypes.func.isRequired
};

export default Modal;
