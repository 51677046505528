import React, { useContext } from 'react';
import Locale from '../locale/en/welcome';
import Header from './Header';
import Subheader from './Subheader';
import Login from './Login';
import SignupOrGuest from './SignupOrGuest';
import { ToolkitContext } from '../context';

const Welcome = () => {
  document.title = 'Welcome to the Financial Toolkit!';
  const { isLoggedIn } = useContext(ToolkitContext);
  return (
    <div className="welcome">
      <div className="content">
        <Header text={Locale.header} />
        <Subheader text={Locale.subheader} />
        <div className="login-logout">
          <Login width="50%" height='auto' alignItems='auto' fontSize='auto' padding='auto' />
          {!isLoggedIn && <SignupOrGuest />}
        </div>
      </div>
    </div>
  );
};

export default Welcome;
