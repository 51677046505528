export default {
  title: 'Results',
  budget: {
    description: 'It looks like you could use some help balancing your household budget. Here are some tools to help with that:',
    checklist: [
      {
        key: 'incomeAndExpenses',
        text: 'Complete the Income and Expenses Worksheet to see how much income you’re bringing in and where you’re spending it. This can help you find places to make spending cuts.'
      },
      {
        key: 'ideas',
        text: 'Take a look at our Ideas for Reducing Expenses and Ideas for Boosting Income. Write down any ideas for cutting costs and increasing income.'
      }
    ]
  },
  savings: {
    description: 'It looks like you could use some help with savings. We have tools to help you set savings goals and make a plan to use them.',
    checklist: [
      {
        key: 'smartGoals',
        text: 'Use our Smart Goals worksheet to help you build an emergency savings reserve. This is critical to making sure you can meet your housing and other expenses in months where your expenses may be high or your income low.'
      }
    ]
  },
  debt: {
    description: 'It looks like you could use help managing your debt. Use our debt and credit Tools to understand what you owe and make a plan to repay them.',
    checklist: [
      {
        key: 'creditAndDebt',
        text: 'Check out our tips on managing credit and debt.'
      },
      {
        key: 'creditReport',
        text: 'Go more in-depth on exploring your credit with our Credit Report Review Checklist.'
      },
      {
        key: 'worksheet',
        text: 'Start planning to pay down your debt with our Debt Management Worksheet.'
      }
    ]
  },
  createAccount: 'Create an account to save your data!',
  ifCloseBrowser: "Watch out! If you close this browser window, you'll lose your data. To permanently save your data, sign up <a href='/en/login'>here.</a>",
  share: 'SHARE RESULTS',
  retake: 'RETAKE QUIZ',
  begin: 'BEGIN',
  edit: 'EDIT'
};
