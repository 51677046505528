import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import axios from 'axios';
import incomeAndExpenses from '../locale/en/incomeAndExpenses';
import ideasToBoostIncome from '../locale/en/ideasToBoostIncome';
import ideasToReduceExpenses from '../locale/en/ideasToReduceExpenses';
import smartSavingsGoals from '../locale/en/smartSavingsGoals';
import manageCreditAndDebt from '../locale/en/manageCreditAndDebt';
import debtManagementWorksheet from '../locale/en/debtManagementWorksheet';
import creditReportReview from '../locale/en/creditReportReview';
import { ToolkitContext } from '../context';
import GlobalLocale from '../locale/en/global';

const locales = {
  ideasToBoostIncome,
  incomeAndExpenses,
  ideasToReduceExpenses,
  smartSavingsGoals,
  manageCreditAndDebt,
  debtManagementWorksheet,
  creditReportReview
};

const ToolMetadata = ({ tool }) => {
  const locale = locales[tool];
  const [numberOfAnswers, setNumberOfAnswers] = useState(0);
  const { isLoggedIn } = useContext(ToolkitContext);

  useEffect(() => {
    if (isLoggedIn) {
      axios({
        method: 'get',
        url: '/answers/count',
        params: { tool: locale.toolNumber }
      }).then((response) => {
        setNumberOfAnswers(response.data.number_of_answers);
      });
    }
  }, [isLoggedIn, locale.toolNumber]);

  const questionsTotal = locale.numberOfQuestions;
  return (
    <div className="tool-metadata">
      <div className="tool-metadata--title">{locale.title}</div>
      <div className="tool-metadata--questions">
        {`${numberOfAnswers} out of ${questionsTotal} Questions Answered`}
      </div>
      <Link
        to={locale.url}
        className="ghost small"
      >
        {numberOfAnswers > 0 ? GlobalLocale.edit : GlobalLocale.begin}
      </Link>
    </div>
  );
};

ToolMetadata.propTypes = {
  tool: PropTypes.string.isRequired
};

export default ToolMetadata;
