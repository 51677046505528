import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import ErrorPage from './ErrorPage';
import Loading from './Loading';
import InputElementRouter from './InputElementRouter';
import { ToolkitContext } from '../context';
import useAxios from '../hooks';
import Locale from '../locale/en/quiz';

const Quiz = () => {
  // TODO onSubmit when not logged in need to set isCheckupComplete to true with context
  const { isLoggedIn } = useContext(ToolkitContext);
  const [answers, setAnswers] = useState({});
  const { questions } = Locale;

  // TODO don't render returnedAnswers. Not needed? How to remove?
  const [{ returnedAnswers, isLoading, hasError }, refetchWithHttpVerb] = useAxios(
    answers,
    Locale.toolNumber,
    'get'
  );

  const deleteQuizAnswers = () => {
    refetchWithHttpVerb('delete');
  };

  useEffect(deleteQuizAnswers, []);

  const submitQuiz = () => {
    refetchWithHttpVerb('post');
    window.location.href = '/en/dashboard';
  };

  if (hasError) { return <ErrorPage />; }
  if (isLoading) { return <Loading />; }

  // TODO fix hardcoded /en/ to use dynamic locale.
  return (
    <div className="quiz">
      <ul>
        {questions.map(question => (
          <div className="question" key={question.key}>
            <h3 className="question-title">{question.number}</h3>
            <InputElementRouter
              question={question}
              setAnswers={setAnswers}
              answers={answers}
            />
          </div>
        ))}
      </ul>
      <div className="quiz-submit">
        {isLoggedIn ? (
          <button
            type="button"
            className="submit"
            onClick={submitQuiz}
          >
            {Locale.submit}
          </button>
        ) : (
          <Link
            to={{ pathname: '/en/dashboard', checkupResults: answers }}
          >
            {Locale.submit}
          </Link>
        )}
      </div>
    </div>
  );
};

export default Quiz;
