import React from 'react';
import PropTypes from 'prop-types';

const DateInput = ({
  question,
  id,
  setAnswers,
  answer
}) => {
  // TODO figure out why event.persist() is needed and why it works
  const handleChange = (event) => {
    event.persist();
    setAnswers(previousAnswers => ({
      ...previousAnswers, [id]: event.target.value
    }));
  };

  // TODO return min and max date dynamically from Date instance
  return (
    <div className="date-input">
      <div className="item-wrapper">
        <div className="item-question">{question}</div>
        <div className="item-answer">
          <input
            type="date"
            id={id}
            value={answer}
            onChange={handleChange}
            min="2018-01-01"
            max="2020-12-31"
          />
        </div>
      </div>
    </div>
  );
};

DateInput.propTypes = {
  question: PropTypes.string.isRequired,
  setAnswers: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  answer: PropTypes.string
};

DateInput.defaultProps = {
  answer: ''
};

export default DateInput;
