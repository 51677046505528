import React from 'react';
import PropTypes from 'prop-types';

const SelectElement = ({
  question,
  id,
  setAnswers,
  answer,
  choices
}) => {
  // TODO figure out why event.persist() is needed and why it works
  const handleChange = (event) => {
    event.persist();
    setAnswers(previousAnswers => ({
      ...previousAnswers, [id]: event.target.value
    }));
  };

  const renderOptions = choices.map(choice => (
    <option key={choice} value={choice}>{choice}</option>
  ));

  return (
    <div className="select-element">
      <div className="item-wrapper">
        <div className="item-question">{question}</div>
        <div className="item-answer">
          <select
            id={id}
            onChange={handleChange}
          >
            {renderOptions}
          </select>
        </div>
      </div>
    </div>
  );
};

SelectElement.propTypes = {
  question: PropTypes.string.isRequired,
  setAnswers: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  answer: PropTypes.string,
  choices: PropTypes.array.isRequired
};

SelectElement.defaultProps = {
  answer: ''
};

export default SelectElement;
