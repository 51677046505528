import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Locale from '../locale/en/manageCreditAndDebt';
import GlobalLocale from '../locale/en/global';
import ToolHeader from './ToolHeader';
import ErrorPage from './ErrorPage';
import Loading from './Loading';
import InputElementRouter from './InputElementRouter';
import Modal from './Modal';
import useAxios from '../hooks';

const ManageCreditAndDebt = ({ isLoggedIn }) => {
  document.title = Locale.title;
  const [answers, setAnswers] = useState({});
  const [showModal, setShowModal] = useState(false);

  const [{ returnedAnswers, isLoading, hasError }, refetchWithHttpVerb] = useAxios(
    answers,
    Locale.toolNumber,
    'get'
  );

  const postData = () => {
    refetchWithHttpVerb('post');
  };

  const deleteData = () => {
    refetchWithHttpVerb('delete');
  };

  useEffect(() => {
    setAnswers(returnedAnswers);
  }, [returnedAnswers]);

  if (hasError) { return <ErrorPage />; }
  if (isLoading) { return <Loading />; }

  if (showModal) {
    return (
      <Modal setShowModal={setShowModal} />
    );
  }

  return (
    <div className="credit-and-debt">
      <div className="wrapper">
        <ToolHeader
          number={Locale.displayToolNumber}
          title={Locale.title}
          answers={answers}
          questions={Locale.numberOfQuestions}
          postData={postData}
          deleteData={deleteData}
        />
        <p className="tool-description">{Locale.description}</p>
      </div>
      <div className="credit-and-debt-container">
        <div className="get-started">
          <h2>{Locale.getStartedHeader}</h2>
        </div>
        <div className="questions">
          <h3>{Locale.creditHeader}</h3>
          {Locale.creditQuestions.map(question => (
            <div key={question.key} className="question">
              <InputElementRouter
                question={question}
                setAnswers={setAnswers}
                answers={answers}
              />
              <div className="learn-more" dangerouslySetInnerHTML={{ __html: question.more }} />
            </div>
          ))}
        </div>
        <div className="questions">
          <h3>{Locale.debtHeader}</h3>
          {Locale.debtQuestions.map(question => (
            <div key={question.key} className="question">
              <InputElementRouter
                question={question}
                setAnswers={setAnswers}
                answers={answers}
              />
              <div className="learn-more" dangerouslySetInnerHTML={{ __html: question.more }} />
            </div>
          ))}
        </div>
        {isLoggedIn && (
          <div className="save-all">
            <button
              type="button"
              onClick={postData}
            >
              {GlobalLocale.saveAll}
            </button>
          </div>
        )}
        <div className="next">
          <Link
            className="small ghost"
            to="/en/credit-report-review"
          >
            {GlobalLocale.nextTool}
          </Link>
          <button
            type="button"
            onClick={window.print}
          >
            {GlobalLocale.printAll}
          </button>
        </div>
      </div>
    </div>
  );
};

ManageCreditAndDebt.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired
};

export default ManageCreditAndDebt;
