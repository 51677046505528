import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Locale from '../locale/en/incomeAndExpenses';
import GlobalLocale from '../locale/en/global';
import InputElementRouter from './InputElementRouter';
import Loading from './Loading';
import ErrorPage from './ErrorPage';
import IncomeAndExpensesTotal from './IncomeAndExpensesTotal';
import ToolHeader from './ToolHeader';
import StrategiesDropDown from './StrategiesDropDown';
import useAxios from '../hooks';
import { formatCurrency, currencyToText } from '../helpers';

const IncomeAndExpenses = ({ isLoggedIn }) => {
  document.title = `${Locale.title} | Financial Toolkit`;
  const [answers, setAnswers] = useState({});
  const { incomeQuestions, expensesQuestions } = Locale;

  const [{ returnedAnswers, isLoading, hasError }, refetchWithHttpVerb] = useAxios(
    answers,
    Locale.toolNumber,
    'get'
  );

  const postData = () => {
    refetchWithHttpVerb('post');
  };

  const deleteData = () => {
    refetchWithHttpVerb('delete');
  };

  useEffect(() => {
    setAnswers(returnedAnswers);
  }, [returnedAnswers]);

  if (hasError) { return <ErrorPage />; }
  if (isLoading) { return <Loading />; }

  const getTotalByKey = (keyToFilterOn) => {
    let total = 0;
    Object.entries(answers).forEach(([key, value]) => {
      if (key.includes(keyToFilterOn) && value.length > 0) {
        total += parseFloat(currencyToText(value), 10);
      }
    });
    return total;
  };

  const renderIncomeTotal = () => (
    <div className="number-input">
      <div className="item-wrapper">
        <div className="item-question">
          {Locale.totalIncome}
        </div>
        <div className="item-answer total">
          {formatCurrency(getTotalByKey('income'))}
        </div>
      </div>
    </div>
  );

  const renderExpensesTotal = () => (
    <div className="number-input">
      <div className="item-wrapper">
        <div className="item-question">
          {Locale.totalExpenses}
        </div>
        <div className="item-answer total">
          {formatCurrency(getTotalByKey('expense'))}
        </div>
      </div>
    </div>
  );

  return (
    <div className="income-and-expenses">
      <div className="wrapper">
        <ToolHeader
          number={Locale.displayToolNumber}
          title={Locale.title}
          answers={answers}
          questions={Locale.questions}
          postData={postData}
          deleteData={deleteData}
        />
        <div className="questions">
          <h2 className="budget-title">{Locale.currentIncome}</h2>
          <div className="budget-desc">{Locale.currentIncomeDesc}</div>
          <ul className="income">
            {incomeQuestions.map(question => (
              <InputElementRouter
                question={question}
                key={question.key}
                id={question.key}
                setAnswers={setAnswers}
                answers={answers}
              />
            ))}
            {renderIncomeTotal()}
          </ul>
          <h2 className="budget-title">{Locale.currentExpenses}</h2>
          <div className="budget-desc">{Locale.currentExpensesDesc}</div>
          <StrategiesDropDown />
          <ul className="expenses">
            {expensesQuestions.map(question => (
              <InputElementRouter
                question={question}
                key={question.key}
                id={question.key}
                setAnswers={setAnswers}
                answers={answers}
              />
            ))}
            {renderExpensesTotal()}
          </ul>
        </div>
        {isLoggedIn && (
          <div className="save-all">
            <button
              type="button"
              onClick={postData}
            >
              {GlobalLocale.saveAll}
            </button>
          </div>
        )}
        <IncomeAndExpensesTotal
          income={getTotalByKey('income')}
          expenses={getTotalByKey('expense')}
        />
        <div className="next">
          <Link
            className="small ghost"
            to="/en/ideas-to-boost-income"
          >
            {GlobalLocale.nextTool}
          </Link>
          <button
            type="button"
            onClick={window.print}
          >
            {GlobalLocale.printAll}
          </button>
        </div>
      </div>
    </div>
  );
};

IncomeAndExpenses.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired
};

export default IncomeAndExpenses;
