import React from 'react';
import Locale from '../locale/en/loading';

const Loading = () => (
  <div className="spinner">
    {Locale.loadingMessage}
  </div>
);

export default Loading;
