export default {
  header: 'Steps to Filing a Dispute',
  subHeader: 'Ask your counselor for a sample letter to send',
  items: [
    'Write a letter to the credit bureau that reported the information. Check to see if other credit bureaus also contain these errors. If so, send letters to them as well.',
    'Provide the account number for the item you feel is not accurate.',
    'For each item, explain concisely why you believe it is not accurate.',
    'If you can, include copies of bills or cleared checks (money order stubs) that prove you paid them on time.',
    'Provide your address and telephone number at the end of the letter so the credit bureau can contact you for more information if necessary.',
    'Make a copy of your letter before you send it to the credit bureau.'
  ]
};
