const PREFIX = 'debt-management';

export default {
  toolNumber: 7,
  displayToolNumber: 'Tool 7',
  title: 'Debt Management Worksheet',
  url: '/en/debt-management',
  pro: 'PRO',
  con: 'CON',
  description: 'Use this worksheet to list where your debts are how much you might owe. This is the first step in managing and reducing your debt.',
  foreclosureDescription: 'If you are facing foreclosure and you have significant credit card or other debts, you may be better off making only the minimum payments on these other debts. That way, you can prioritize saving money which will help prove to the bank that you can afford your mortgage. Talk with a housing or financial counselor to discuss what option is best for you. If foreclosure is not an issue for you, there are two strategies to pay down other debts:',
  rateHeader: '1. Highest Interest Rate Method',
  rateDescription: 'Focus on the unsecured debt with the highest rate of interest, and eliminate it as quickly as possible because it is likely costing you the most. Once it is paid off, focus on the next most expensive debt.',
  ratePro: 'You eliminate the most costly debt first.',
  rateCon: 'You may not feel like you are making progress very quickly, especially if this debt is large.',
  snowballHeader: '2. Snowball Method',
  snowballDescription: "Focus on the smallest debt. Get rid of it as soon as possible. Once you have it paid off in full, continue making payments onto the next smallest debt. You create a 'snowball of debt payments' that keeps getting bigger as you eliminate each debt. How? You keep making the payments, but you are redirecting them to the next debt as each debt is paid off.",
  snowballPro: 'You may see progress quickly, especially if you have many small debts. For some people, this creates momentum and motivation.',
  snowballCon: 'You pay more in total because you are not necessarily eliminating your most costly debt.',
  note: 'Note: If you have an account on your credit report that went to collections, you are probably concerned about your credit score. Unfortunately, paying that debt will not necessarily remove the damage it already caused to your score. In some unique cases, you may be better off not making a payment on an item that has gone to collections and is nearing the statute of limitations. But, be sure to consult with a debt management specialist for advice on your particular situation.',
  questions: [
    {
      key: `${PREFIX}-type`,
      type: 'select',
      choices: [
        'Other',
        'Mortgage',
        'Student Loan',
        'Credit Card',
        'Applicant/Furniture',
        'Payday'
      ],
      title: 'SELECT A TYPE OF DEBT'
    },
    {
      key: `${PREFIX}-name`,
      type: 'text',
      title: 'Lender Name',
      placeholder: 'e.g. Chase'
    },
    {
      key: `${PREFIX}-amount`,
      type: 'number',
      title: 'Total Amount Borrowed'
    },
    {
      key: `${PREFIX}-outstanding`,
      type: 'number',
      title: 'Amount Unpaid or Outstanding'
    },
    {
      key: `${PREFIX}-monthly`,
      type: 'number',
      title: 'Monthly Payment Amount'
    },
    {
      key: `${PREFIX}-due-date`,
      type: 'date',
      title: 'Payment Due Date'
    },
    {
      key: `${PREFIX}-collateral`,
      type: 'radio',
      title: "Is this loan 'secured' (with collateral like a home, car or savings)?"
    },
    {
      key: `${PREFIX}-interest`,
      type: 'number',
      title: 'Interest Rate (Annual Percentage Rate)'
    },
    {
      key: `${PREFIX}-terms`,
      type: 'text-area',
      title: 'Other Important Terms',
      placeholder: ''
    }
  ],
  numberOfQuestions: 9
};
