import React from 'react';
import PropTypes from 'prop-types';
import ChecklistItem from './ChecklistItem';

const Checklist = ({ items, miniCheckmark }) => items.map(item => (
  <ChecklistItem key={item.key} text={item.text} miniCheckmark={miniCheckmark} />
));

Checklist.propTypes = {
  miniCheckmark: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    }).isRequired
  ).isRequired
};

Checklist.defaultProps = {
  miniCheckmark: false
};

export default Checklist;
