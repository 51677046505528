export default {
  siteName: 'Financial Health Toolkit',
  privacyCookies: 'Privacy & Cookies',
  terms: 'Terms of Use',
  save: 'SAVE',
  saveAll: 'SAVE ALL',
  deleteAll: 'DELETE ALL',
  printAll: 'PRINT',
  begin: 'BEGIN',
  edit: 'EDIT',
  next: 'NEXT',
  nextTool: 'NEXT TOOL',
  selectDate: 'I will do this by:'
};
