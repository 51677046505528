import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import axios from 'axios';
import incomeAndExpenses from '../locale/en/incomeAndExpenses';
import ideasToBoostIncome from '../locale/en/ideasToBoostIncome';
import ideasToReduceExpenses from '../locale/en/ideasToReduceExpenses';
import smartSavingsGoals from '../locale/en/smartSavingsGoals';
import manageCreditAndDebt from '../locale/en/manageCreditAndDebt';
import debtManagementWorksheet from '../locale/en/debtManagementWorksheet';
import creditReportReview from '../locale/en/creditReportReview';
import { ToolkitContext } from '../context';
import GlobalLocale from '../locale/en/global';

const allToolLocales = [
  incomeAndExpenses,
  ideasToBoostIncome,
  ideasToReduceExpenses,
  smartSavingsGoals,
  manageCreditAndDebt,
  creditReportReview,
  debtManagementWorksheet
];

const suggestedToolLocalesFromQuizResults = [
  [
    incomeAndExpenses,
    ideasToBoostIncome,
    ideasToReduceExpenses
  ],
  [smartSavingsGoals],
  [
    manageCreditAndDebt,
    creditReportReview,
    debtManagementWorksheet
  ]
];

const ToolBanner = ({ suggestedTools }) => {
  // Default to all tools
  const [suggestedToolLocales, setSuggestedToolLocales] = useState(allToolLocales);
  const [numberOfAnswers, setNumberOfAnswers] = useState(0);
  const [activeTool, setActiveTool] = useState(0);
  const { isLoggedIn } = useContext(ToolkitContext);

  const getSuggestedToolLocales = () => {
    const locales = [];
    suggestedToolLocalesFromQuizResults.forEach((localeArray, idx) => {
      if (suggestedTools.includes(idx + 1)) {
        locales.push(...localeArray);
      }
    });
    return locales;
  };

  useEffect(() => {
    if (isLoggedIn) {
      axios({
        method: 'get',
        url: '/answers/count',
        params: { tool: activeTool }
      }).then((response) => {
        setNumberOfAnswers(response.data.number_of_answers);
      });
    }
    if (suggestedTools.length > 0) {
      setSuggestedToolLocales(getSuggestedToolLocales());
    }
  }, [activeTool, isLoggedIn, suggestedTools]);

  return (
    <div className="tool-banner">
      <span className="description">{suggestedToolLocales[activeTool].title}</span>
      <div className="tool-metadata">
        <div className="title">{suggestedToolLocales[activeTool].displayToolNumber}</div>
        <div className="questions">
          {`${numberOfAnswers} out of ${suggestedToolLocales[activeTool].numberOfQuestions} Questions Answered`}
        </div>
        <Link
          className="ghost small inverse"
          to={suggestedToolLocales[activeTool].url}
        >
          {GlobalLocale.begin}
        </Link>
        <button
          type="button"
          className="ghost small inverse"
          onClick={() => setActiveTool((prevTool) => {
            if (prevTool === (suggestedToolLocales.length - 1)) {
              return 0;
            }
            return prevTool + 1;
          })}
        >
          {GlobalLocale.next}
        </button>
      </div>
    </div>
  );
};

ToolBanner.propTypes = {
  suggestedTools: PropTypes.arrayOf(PropTypes.number).isRequired
};

export default ToolBanner;
