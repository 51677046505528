const PREFIX = 'credit-report';

export default {
  toolNumber: 6,
  displayToolNumber: 'Tool 6',
  title: 'Credit Report Review Checklist',
  url: '/en/credit-report-review',
  description: "If you receive in-person housing counseling or legal services, your counselor or attorney may pull your credit report for you. For anyone struggling with debt, it's important to review your credit report carefully to understand what you owe and confirm the information on your report is accurate.",
  download: 'You can download your free credit reports from all three major agencies once a year at annualcreditreport.com or by calling the Federal Trade Commission at (877) 322-8228. <p> Sign up for a Credit Karma account to monitor your credit score. </p>',
  questionHeader: 'Your credit report contains a lot of personal and financial information. Be sure to keep any hard copies of your credit report in a safe and secure place. If you do not want to keep your credit reports, be sure to shred them before you throw them away.',
  questionFooter: 'If you find something wrong with your credit report, you should dispute it. To correct mistakes, it can help to contact both the credit reporting company and the company who reported the mistake.',
  questions: [
    {
      key: `${PREFIX}-ssn-correct`,
      type: 'radio',
      title: 'Is your name correct? Is your Social Security number correct?',
      single: true
    },
    {
      key: `${PREFIX}-address`,
      type: 'radio',
      title: 'Is your current address correct? Is your current phone number correct?',
      single: true
    },
    {
      key: `${PREFIX}-previous-address`,
      type: 'radio',
      title: 'Are the previous addresses they have listed for you correct?',
      single: true
    },
    {
      key: `${PREFIX}-marital`,
      type: 'radio',
      title: 'Is your marital status listed correctly?',
      single: true
    },
    {
      key: `${PREFIX}-employment`,
      type: 'radio',
      title: 'Is the employment history they have listed for you accurate?',
      single: true
    },
    {
      key: `${PREFIX}-public-record`,
      type: 'radio',
      title: 'Is there anything listed in public record? Highlight the information you think may not be correct.',
      single: true
    },
    {
      key: `${PREFIX}-trade-account`,
      type: 'radio',
      title: 'Review each item under the credit account (trade account) section. Are the accounts on the list still open?',
      single: true
    },
    {
      key: `${PREFIX}-correct`,
      type: 'radio',
      title: 'Are all of the current balances correct?',
      single: true
    },
    {
      key: `${PREFIX}-authorized`,
      type: 'radio',
      title: 'Are accounts where  you are an authorized user or joint owner listed?',
      single: true
    },
    {
      key: `${PREFIX}-debts`,
      type: 'radio',
      title: 'Are debts you paid in full recorded as zero balances? What about debts discharged in bankruptcy?',
      single: true
    },
    {
      key: `${PREFIX}-co-signer`,
      type: 'radio',
      title: 'Are you listed as a co-signer on a loan? Is this correct?',
      single: true
    },
    {
      key: `${PREFIX}-closed`,
      type: 'radio',
      title: "Are accounts that you closed listed as 'closed by the consumer'?",
      single: true
    },
    {
      key: `${PREFIX}-negative-info`,
      type: 'radio',
      title: 'Is any negative information reported on each credit account correct? Look for late payments and missed payments. Highlight items you think are not correct.',
      single: true
    },
    {
      key: `${PREFIX}-listing`,
      type: 'radio',
      title: 'Are any accounts listed more than once? Check to make sure the same account is not listed multiple times in the collections section.',
      single: true
    },
    {
      key: `${PREFIX}-old-info`,
      type: 'radio',
      title: 'Is old negative information still being reported? If yes, highlight the information that has exceeded the negative information reporting limit, which is usually seven years.',
      single: true
    },
    {
      key: `${PREFIX}-review`,
      type: 'radio',
      title: 'After reviewing your credit reports, do you suspect that you have been the victim of identity theft?',
      single: true
    }
  ],
  numberOfQuestions: 16
};
