import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import GlobalLocale from '../locale/en/global';
import Locale from '../locale/en/ideasToReduceExpenses';
import ToolHeader from './ToolHeader';
import ErrorPage from './ErrorPage';
import InputElementRouter from './InputElementRouter';
import Loading from './Loading';
import useAxios from '../hooks';
import { getTotal } from '../helpers';

const IdeasToReduceExpenses = ({ isLoggedIn }) => {
  document.title = Locale.title;
  const [answers, setAnswers] = useState({});

  const [{ returnedAnswers, isLoading, hasError }, refetchWithHttpVerb] = useAxios(
    answers,
    Locale.toolNumber,
    'get'
  );

  const postData = () => {
    refetchWithHttpVerb('post');
  };

  const deleteData = () => {
    refetchWithHttpVerb('delete');
  };

  useEffect(() => {
    setAnswers(returnedAnswers);
  }, [returnedAnswers]);

  if (hasError) { return <ErrorPage />; }
  if (isLoading) { return <Loading />; }

  return (
    <div className="ideas-to-reduce-expenses">
      <div className="wrapper">
        <ToolHeader
          number={Locale.displayToolNumber}
          title={Locale.title}
          answers={answers}
          questions={Locale.numberOfQuestions}
          postData={postData}
          deleteData={deleteData}
        />
        <p className="tool-description">{Locale.description}</p>
      </div>
      <div className="ideas">
        {Locale.questions.map(question => (
          <div key={question.key} className="idea">
            <InputElementRouter
              question={question}
              setAnswers={setAnswers}
              answers={answers}
            />
          </div>
        ))}
        <div className="idea custom-title">
          {Locale.userGeneratedQuestions.map(question => (
            <div key={question.key}>
              <div className="idea-title">{question.question}</div>
              <div className="idea-description">{question.description}</div>
              <div className="savings">
                <InputElementRouter
                  question={question}
                  setAnswers={setAnswers}
                  answers={answers}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="wrapper">
        {isLoggedIn && (
          <div className="button-wrapper">
            <button
              type="button"
              onClick={postData}
              className="save-all"
            >
              {GlobalLocale.saveAll}
            </button>
          </div>
        )}
        <div className="total-and-share">
          <div className="full-width-box">
            <div className="total">
              <div className="idea-title">{Locale.estimate}</div>
              <div className="idea-title total-amount">
                {getTotal(answers)}
              </div>
            </div>
            <div className="idea-description">{Locale.share}</div>
          </div>
        </div>
      </div>
      <div className="next">
        <Link
          className="small ghost"
          to="/en/smart-savings-goals"
        >
          {GlobalLocale.nextTool}
        </Link>
        <button
          type="button"
          onClick={window.print}
        >
          {GlobalLocale.printAll}
        </button>
      </div>
    </div>
  );
};

IdeasToReduceExpenses.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired
};

export default IdeasToReduceExpenses;
