import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Locale from '../locale/en/creditReportReview';
import GlobalLocale from '../locale/en/global';
import ToolHeader from './ToolHeader';
import ErrorPage from './ErrorPage';
import Loading from './Loading';
import InputElementRouter from './InputElementRouter';
import useAxios from '../hooks';

const CreditReportReview = ({ isLoggedIn }) => {
  document.title = Locale.title;
  const [answers, setAnswers] = useState({});

  const [{ returnedAnswers, isLoading, hasError }, refetchWithHttpVerb] = useAxios(
    answers,
    Locale.toolNumber,
    'get'
  );

  const postData = () => {
    refetchWithHttpVerb('post');
  };

  const deleteData = () => {
    refetchWithHttpVerb('delete');
  };

  useEffect(() => {
    setAnswers(returnedAnswers);
  }, [returnedAnswers]);

  if (hasError) { return <ErrorPage />; }
  if (isLoading) { return <Loading />; }

  return (
    <div className="credit-report-review">
      <div className="wrapper">
        <ToolHeader
          number={Locale.displayToolNumber}
          title={Locale.title}
          answers={answers}
          questions={Locale.numberOfQuestions}
          postData={postData}
          deleteData={deleteData}
        />
        <p className="tool-description">{Locale.description}</p>
      </div>
      <div className="download" dangerouslySetInnerHTML={{ __html: Locale.download }} />
      <div className="credit-container">
        <div className="questions">
          {Locale.questionHeader}
          {Locale.questions.map(question => (
            <div key={question.key} className="question">
              <InputElementRouter
                question={question}
                setAnswers={setAnswers}
                answers={answers}
              />
            </div>
          ))}
        </div>
        {isLoggedIn && (
          <div className="save-all">
            <button
              type="button"
              onClick={postData}
            >
              {GlobalLocale.saveAll}
            </button>
          </div>
        )}
        <div className="credit-dispute">
          {Locale.questionFooter}
        </div>
        <div className="next">
          <Link
            className="small ghost"
            to="/en/debt-management"
          >
            {GlobalLocale.nextTool}
          </Link>
          <button
            type="button"
            onClick={window.print}
          >
            {GlobalLocale.printAll}
          </button>
        </div>
      </div>
    </div>
  );
};

CreditReportReview.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired
};

export default CreditReportReview;
