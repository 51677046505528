import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import Login from './Login';
import Welcome from './Welcome';
import Checkup from './Checkup';
import Dashboard from './Dashboard';
import IncomeAndExpenses from './IncomeAndExpenses';
import IdeasToBoostIncome from './IdeasToBoostIncome';
import IdeasToReduceExpenses from './IdeasToReduceExpenses';
import DebtManagementWorksheet from './DebtManagementWorksheet';
import ManageCreditAndDebt from './ManageCreditAndDebt';
import CreditReportReview from './CreditReportReview';
import SmartSavingsGoals from './SmartSavingsGoals';
import NoMatch from './NoMatch';
import { ToolkitContext } from '../context';

// This is a list of all of our routes and the correspondoing content to be
// rendered on that route
// NOTE if a route matches a sub-route (e.g. /hello and /hello/world) the
// content from both /hello AND /hello/world will be rendered when on /hello/world.
// This can be fixed by specifying 'exact' on the parent route
// NOTE with the updated syntax below from ReactRouter 5.1 we can pass props
// to the children components in the Router e.g. <Dashboard loggedIn={false} />
// This is very nifty.

const AppRouter = () => {
  const { isLoggedIn } = useContext(ToolkitContext);

  return (
    <Switch>
      <Route path="/en/login">
        <Login
          width="100%"
          height="80vh"
          alignItems="center"
          fontSize="1.2em"
          padding="5%"
        />
      </Route>
      <Route path="/en/checkup">
        <Checkup />
      </Route>
      <Route path="/en/dashboard">
        <Dashboard />
      </Route>
      <Route path="/en/income-and-expenses">
        <IncomeAndExpenses
          isLoggedIn={isLoggedIn}
        />
      </Route>
      <Route path="/en/ideas-to-boost-income">
        <IdeasToBoostIncome
          isLoggedIn={isLoggedIn}
        />
      </Route>
      <Route path="/en/ideas-to-reduce-expenses">
        <IdeasToReduceExpenses
          isLoggedIn={isLoggedIn}
        />
      </Route>
      <Route path="/en/smart-savings-goals">
        <SmartSavingsGoals
          isLoggedIn={isLoggedIn}
        />
      </Route>
      <Route path="/en/manage-credit-and-debt">
        <ManageCreditAndDebt
          isLoggedIn={isLoggedIn}
        />
      </Route>
      <Route path="/en/credit-report-review">
        <CreditReportReview
          isLoggedIn={isLoggedIn}
        />
      </Route>
      <Route path="/en/debt-management">
        <DebtManagementWorksheet
          isLoggedIn={isLoggedIn}
        />
      </Route>
      <Route exact path="/">
        <Welcome />
      </Route>
      <Route>
        <NoMatch />
      </Route>
    </Switch>
  );
};


export default AppRouter;
