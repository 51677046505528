import React from 'react';
import Locale from '../locale/en/errorPage';

const ErrorPage = () => (
  <div className="error">
    <h2>
      {Locale.message}
    </h2>
    <div className="contact">
      <p>{Locale.contactMessage}</p>
      <a href={`tel:${Locale.contactPhone}`}>
        {Locale.contactPhone}
      </a>
      <a href={`mailto:${Locale.contactEmail}`}>
        {Locale.contactEmail}
      </a>
    </div>
  </div>
);

export default ErrorPage;
