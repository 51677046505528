const PREFIX = 'manage-credit-and-debt';

export default {
  toolNumber: 5,
  displayToolNumber: 'Tool 5',
  title: 'Ways to Manage Credit and Debt',
  url: '/en/manage-credit-and-debt',
  description: 'Homeowners have successfully used the strategies below to handle debt and repair their credit. See if they might work for you.',
  getStartedHeader: 'What steps will you take to get started?',
  dispute: 'Steps to filing a credit dispute',
  debtHeader: "IF YOU'RE STRUGGLING WITH DEBT:",
  creditHeader: "IF YOU'RE WORKING ON BUILDING CREDIT:",
  resultsHeader: 'What action steps do you need to get started?',
  creditQuestions: [
    {
      key: `${PREFIX}-knowledge`,
      type: 'toggle-date',
      title: 'Knowledge is power - read your credit report line by line.',
      single: true,
      more: 'You can download your free credit reports once a year from all three major agencies: Equifax, TransUnion, and Experian at <a href="www.annualcreditreport.com"> www.annualcreditreport.com</a>. Check to see if there are errors on your report, which can reduce your score and could mean you get higher interest rates in the future.'
    },
    {
      key: `${PREFIX}-errors`,
      type: 'toggle-date',
      title: 'If there are errors on your credit report, you can file a dispute.',
      single: true,
      more: 'File disputes with the credit reporting agency using instructions on <a href="/en/credit-report-review">the Credit Report Checklist</a>. You may also want to ask a housing counselor or legal services staff for help in crafting a personal statement to add to your credit report.'
    },
    {
      key: `${PREFIX}-sign-up`,
      type: 'toggle-date',
      title: 'Sign up for <a href="https://www.creditkarma.com"> www.creditkarma.com </a>',
      single: true,
      more: '<a href="https://www1.nyc.gov/site/dca/consumers/credit-repair-scams.page">Beware of scammers who say they can “fix” your credit score</a>, especially if they make you pay a fee in advance.'
    },
    {
      key: `${PREFIX}-balance`,
      type: 'toggle-date',
      title: 'If you have a higher credit card balance than you can pay each month, keep making minimum payments on your credit cards.',
      single: true,
      more: 'Payment history is the biggest factor in determining your credit score. Always make your minimum payment on credit cards. Another important factor in determining your credit score is how much you spend each month relative to your total available credit. This is called utilization. When possible, try to utilize less than one-third of your total available credit across all cards. Payment history is the biggest factor in determining your credit score. Always make your minimum payment on credit cards. Another important factor in determining your credit score is how much you spend each month relative to your total available credit. This is called “utilization.” When possible, try to utilize less than one-third of your total available credit across all cards.'
    },
    {
      key: `${PREFIX}-automatic-payments`,
      type: 'toggle-date',
      title: "Sign up for automatic payments to pay credit cards and other loans so you don't accidentally miss your due date.",
      single: true,
      more: 'Check with your lenders to see if they have automatic payment options available.'
    },
    {
      key: `${PREFIX}-history`,
      type: 'toggle-date',
      title: "If you don't have much credit history, consider signing up for a 'credit builder' account.",
      single: true,
      more: 'Many banks and credit unions offer secured loan accounts or secured credit cards that can help build a positive credit record. After six months with a new account and on-time payments, your credit score could go up by as much as 100 points! A housing or financial counselor can give you a referral.'
    }
  ],
  debtQuestions: [
    {
      key: `${PREFIX}-worksheet`,
      type: 'toggle-date',
      title: 'Fill out a <a href="/en/debt-management">Debt Management Worksheet</a> to help you prioritize how to pay your debts.',
      single: true,
      more: 'Be sure to check your <a href="/en/income-and-expenses">income and expenses worksheet</a> to determine how much you have available for debt payments.'
    },
    {
      key: `${PREFIX}-make-payments`,
      type: 'toggle-date',
      title: "If you're struggling to make payments, consider calling your creditors.",
      single: true,
      more: 'Asking for lowered interest rates or an easier payment plan is a viable strategy.'
    },
    {
      key: `${PREFIX}-financial-counselor`,
      type: 'toggle-date',
      title: "If you're having trouble making a plan to manage your debt, a financial counselor can help you.",
      single: true,
      more: 'If you’re working with a housing counselor or legal services provider, ask them for a referral to free financial counselor, or visit a <a href="https://www1.nyc.gov/site/dca/consumers/get-free-financial-counseling.page">Financial Empowerment Center</a> near you.'
    },
    {
      key: `${PREFIX}-consolidation`,
      type: 'toggle-date',
      title: 'Consider using a reputable non-profit debt consolidation service.',
      single: true,
      more: 'Services like <a href="www.greenpath.com">Green Path</a> charge fees for their services, so only use them if your situation is complex. Be cautious of other companies that claim to settle your debt for a fee.'
    },
    {
      key: `${PREFIX}-collections`,
      type: 'toggle-date',
      title: 'If debt collectors are calling, know what to do.',
      single: true,
      more: 'First, ask them to send you written information on the debt to make sure it is valid. It is important that you review the debt before confirming you owe it or making an agreement to pay. New York City law requires that debt collectors send written verification within 5 days of contacting you. They must include the name of the original creditor, the amount owed, and instructions on how to dispute the debt.'
    }
  ],
  numberOfQuestions: 11
};
