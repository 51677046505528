import React from 'react';
import Locale from '../locale/en/login';

const Logout = () => (
  <form action="/logout">
    <input type="submit" value={Locale.logout} />
  </form>
);

export default Logout;
