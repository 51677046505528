import React from 'react';
import { Link } from 'react-router-dom';
import Locale from '../locale/en/signupOrGuest';

const SignupOrGuest = () => (
  <div className="signup">
    <h4>{Locale.header}</h4>
    <Link
      className="welcome-button"
      to="/en/checkup"
    >
      {Locale.button}
    </Link>
    <p>{Locale.body}</p>
  </div>
);

export default SignupOrGuest;
