const PREFIX = 'smart-savings';

export default {
  toolNumber: 4,
  displayToolNumber: 'Tool 4',
  title: 'SMART Savings Goals & Action Plan Worksheet',
  url: '/en/smart-savings-goals',
  description: "It's easier to achieve a financial goal if it is 'SMART' - Specific, Measurable, Achievable, Relevant, and Time-Specific.",
  goalHeaders: [
    '1. SPECIFIC',
    '2. MEASURABLE',
    '3. ACHIEVABLE',
    '4. RELEVANT',
    '5. TIME-SPECIFIC'
  ],
  strategyHeader: 'STRATEGIES TO REACH MY GOAL',
  strategyDescription: "When you set a goal, such as to save a certain amount of money, it is often necessary to boost your income or reduce expenses to achieve this goal. Use this worksheet to write down some of the strategies you'll use to achive your goal.",
  goalsSpecific: [
    {
      key: `${PREFIX}-savings-goal`,
      type: 'text',
      title: 'I commit to set aside money each month for this savings goal:',
      placeholder: 'e.g. Housing, Repairs, etc...'
    },
    {
      key: `${PREFIX}-deposit`,
      type: 'text',
      title: 'I will deposit money into this bank account:',
      placeholder: 'Bank name - Account type'
    }
  ],
  goalsMeasurable: [
    {
      key: `${PREFIX}-measurable-amount`,
      type: 'number',
      title: 'I will set aside'
    },
    {
      key: `${PREFIX}-measurable-timeline`,
      type: 'select',
      title: 'every',
      choices: [
        'Week',
        'Two weeks',
        'Month'
      ]
    },
    {
      key: `${PREFIX}-measurable-method`,
      type: 'select',
      title: 'through',
      choices: [
        'Automatic transfer',
        'Manual deposit'
      ]
    }
  ],
  goalsAchievable: [
    {
      key: `${PREFIX}-achievable-amount`,
      type: 'text',
      title: 'This amount is realistic for me. I will use money from this income source to make my deposits:',
      placeholder: 'Income source'
    }
  ],
  goalsRelevant: [
    {
      key: `${PREFIX}-relevant`,
      type: 'radio',
      title: 'Are you setting an appropriate goal for the current time?'
    }
  ],
  goalsTimeSpecific: [
    {
      key: `${PREFIX}-time-specific`,
      type: 'date',
      title: 'I will start depositing housing payments to my account by this date:'
    }
  ],
  strategyQuestions: [
    {
      key: `${PREFIX}-strategy-text-area`,
      type: 'text-area',
      title: 'Strategy:',
      placeholder: 'e.g. Cut Dish TV, Switch to basic cable'
    },
    {
      key: `${PREFIX}-strategy-amount`,
      type: 'number',
      title: 'Amount:'
    },
    {
      key: `${PREFIX}-strategy-start`,
      type: 'date',
      title: 'Start Date:'
    },
    {
      key: `${PREFIX}-strategy-frequency`,
      type: 'select',
      title: 'Frequency:',
      choices: [
        'daily',
        'monthly',
        'weekly'
      ]
    },
    {
      key: `${PREFIX}-strategy-steps`,
      type: 'text-area',
      title: 'Action Plan Steps:',
      placeholder: 'e.g. Discuss with family'
    }
  ],
  otherQuestions: [
    {
      type: 'text',
      key: `${PREFIX}-name`,
      title: 'I commit to these strategies to save this amount monthly.',
      placeholder: 'Your Name'
    }
  ],
  numberOfQuestions: 14
};
