import React from 'react';
import PropTypes from 'prop-types';
import RadioInput from './RadioInput';
import SingleCheckboxInput from './SingleCheckboxInput';
import MultipleCheckboxInput from './MultipleCheckboxInput';
import NumberInput from './NumberInput';
import DateInput from './DateInput';
import ToggleDateInput from './ToggleDateInput';
import SelectElement from './SelectElement';
import TextInput from './TextInput';
import TextAreaElement from './TextAreaElement';

const InputElementRouter = ({ question, setAnswers, answers }) => {
  const { key } = question;
  switch (question.type) {
    case 'radio':
      return (
        <RadioInput
          question={question.title}
          id={key}
          setAnswers={setAnswers}
          answer={answers[key]}
          singleButton={question.single}
        />
      );
    case 'checkbox':
      return (
        <SingleCheckboxInput
          question={question.title}
          id={key}
          setAnswers={setAnswers}
          answer={answers[key]}
          choices={question.choices}
        />
      );
    case 'multiple':
      return (
        <MultipleCheckboxInput
          question={question.title}
          id={key}
          setAnswers={setAnswers}
          answer={answers[key]}
          choices={question.choices}
        />
      );
    case 'number':
      return (
        <NumberInput
          question={question.title}
          description={question.description}
          id={key}
          setAnswers={setAnswers}
          answer={answers[key]}
        />
      );
    case 'text':
      return (
        <TextInput
          question={question.title}
          placeholder={question.placeholder}
          id={key}
          setAnswers={setAnswers}
          answer={answers[key]}
        />
      );
    case 'text-area':
      return (
        <TextAreaElement
          question={question.title}
          placeholder={question.placeholder}
          id={key}
          setAnswers={setAnswers}
          answer={answers[key]}
        />
      );
    case 'select':
      return (
        <SelectElement
          question={question.title}
          id={key}
          setAnswers={setAnswers}
          answer={answers[key]}
          choices={question.choices}
        />
      );
    case 'date':
      return (
        <DateInput
          question={question.title}
          id={key}
          setAnswers={setAnswers}
          answer={answers[key]}
        />
      );
    case 'toggle-date':
      return (
        <ToggleDateInput
          question={question.title}
          id={key}
          setAnswers={setAnswers}
          answer={answers[key]}
        />
      );
    default:
      return null;
  }
};

InputElementRouter.propTypes = {
  question: PropTypes.shape({
    key: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    choices: PropTypes.array,
    single: PropTypes.bool
  }).isRequired,
  setAnswers: PropTypes.func.isRequired,
  answers: PropTypes.object
};

export default InputElementRouter;
