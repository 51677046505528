import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Locale from '../locale/en/smartSavingsGoals';
import GlobalLocale from '../locale/en/global';
import ToolHeader from './ToolHeader';
import InputElementRouter from './InputElementRouter';
import ErrorPage from './ErrorPage';
import Loading from './Loading';
import useAxios from '../hooks';

const SmartSavingsGoals = ({ isLoggedIn }) => {
  document.title = Locale.title;
  const [answers, setAnswers] = useState({});

  const [{ returnedAnswers, isLoading, hasError }, refetchWithHttpVerb] = useAxios(
    answers,
    Locale.toolNumber,
    'get'
  );

  const postData = () => {
    refetchWithHttpVerb('post');
  };

  const deleteData = () => {
    refetchWithHttpVerb('delete');
  };

  useEffect(() => {
    setAnswers(returnedAnswers);
  }, [returnedAnswers]);

  if (hasError) { return <ErrorPage />; }
  if (isLoading) { return <Loading />; }

  // TODO calculate per month and per year in strategy based off of amount and frequency
  // TODO calculate total monthly savings at bottom
  return (
    <div className="smart-savings">
      <div className="wrapper">
        <ToolHeader
          number={Locale.displayToolNumber}
          title={Locale.title}
          answers={answers}
          questions={Locale.numberOfQuestions}
          postData={postData}
          deleteData={deleteData}
        />
        <p className="tool-description">{Locale.description}</p>
      </div>
      <div className="smart-savings-container">
        <div className="smart-savings-questions">
          <h3>{Locale.goalHeaders[0]}</h3>
          {Locale.goalsSpecific.map(goal => (
            <div key={goal.key} className="saving">
              <InputElementRouter
                question={goal}
                setAnswers={setAnswers}
                answers={answers}
              />
            </div>
          ))}
          <h3>{Locale.goalHeaders[1]}</h3>
          {Locale.goalsMeasurable.map(goal => (
            <div key={goal.key} className="saving">
              <InputElementRouter
                question={goal}
                setAnswers={setAnswers}
                answers={answers}
              />
            </div>
          ))}
          <h3>{Locale.goalHeaders[2]}</h3>
          {Locale.goalsAchievable.map(goal => (
            <div key={goal.key} className="saving">
              <InputElementRouter
                question={goal}
                setAnswers={setAnswers}
                answers={answers}
              />
            </div>
          ))}
          <h3>{Locale.goalHeaders[3]}</h3>
          {Locale.goalsRelevant.map(goal => (
            <div key={goal.key} className="saving">
              <InputElementRouter
                question={goal}
                setAnswers={setAnswers}
                answers={answers}
              />
            </div>
          ))}
          <h3>{Locale.goalHeaders[4]}</h3>
          {Locale.goalsTimeSpecific.map(goal => (
            <div key={goal.key} className="saving">
              <InputElementRouter
                question={goal}
                setAnswers={setAnswers}
                answers={answers}
              />
            </div>
          ))}
          <div className="strategy-wrapper">
            <h3>{Locale.strategyHeader}</h3>
            <p>{Locale.strategyDescription}</p>
            {Locale.strategyQuestions.map(question => (
              <div key={question.key} className="saving">
                <InputElementRouter
                  question={question}
                  setAnswers={setAnswers}
                  answers={answers}
                />
              </div>
            ))}
          </div>
        </div>
        {isLoggedIn && (
          <div className="save-all">
            <button
              type="button"
              onClick={postData}
            >
              {GlobalLocale.saveAll}
            </button>
          </div>
        )}
        <div className="name-box">
          {Locale.otherQuestions.map(question => (
            <div key={question.key} className="saving">
              <InputElementRouter
                question={question}
                setAnswers={setAnswers}
                answers={answers}
              />
            </div>
          ))}
        </div>
        <div className="next">
          <Link
            className="small ghost"
            to="/en/manage-credit-and-debt"
          >
            {GlobalLocale.nextTool}
          </Link>
          <button
            type="button"
            onClick={window.print}
          >
            {GlobalLocale.printAll}
          </button>
        </div>
      </div>
    </div>
  );
};

SmartSavingsGoals.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired
};

export default SmartSavingsGoals;
