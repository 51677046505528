import React from 'react';
import Locale from '../locale/en/checkup';
import Header from './Header';
import Subheader from './Subheader';
import Quiz from './Quiz';
import Checklist from './Checklist';

const Checkup = () => (
  <div className="checkup">
    <div className="intro">
      <Header text={Locale.header} />
      <Subheader text={Locale.subheader} />
      <Checklist items={Locale.checklistItems} />
    </div>
    <Quiz />
  </div>
);

export default Checkup;
