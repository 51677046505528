import React, { useContext } from 'react';
import { Prompt } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ToolkitContext } from '../context';
import GlobalLocale from '../locale/en/global';

const ToolHeader = ({
  number,
  title,
  answers,
  questions,
  postData,
  deleteData
}) => {
  const questionsAnswered = () => {
    const numberOfAnswers = Object.keys(answers).length;
    return (`${numberOfAnswers} of ${questions} Questions Answered`);
  };

  const { isLoggedIn } = useContext(ToolkitContext);

  return (
    <div className="tool-header">
      <div className="tool-meta">
        <div className="tool-number">{number}</div>
        <h1 className="tool-title">{title}</h1>
        <div className="questions-answered">
          {questionsAnswered()}
        </div>
      </div>
      <div className="buttons">
        {isLoggedIn && (
          <>
            <button
              type="button"
              onClick={postData}
            >
              {GlobalLocale.saveAll}
            </button>
            <button
              type="button"
              onClick={deleteData}
            >
              {GlobalLocale.deleteAll}
            </button>
          </>
        )}
        <button
          type="button"
          onClick={window.print}
        >
          {GlobalLocale.printAll}
        </button>
      </div>
      <Prompt
        when={!isLoggedIn}
        message={location => (
          location.pathname.startsWith('/en/login') ?
            true :
            'Work cannot be saved until you are logged in'
        )
       }
      />
    </div>
  );
};

ToolHeader.propTypes = {
  number: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  answers: PropTypes.object.isRequired,
  questions: PropTypes.number.isRequired,
  deleteData: PropTypes.func.isRequired,
  postData: PropTypes.func.isRequired
};

export default ToolHeader;
