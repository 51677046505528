const PREFIX = 'tool-1';
const INCOME = 'income';
const EXPENSE = 'expense';

const incomeQuestions = [
  {
    type: 'number',
    key: `${PREFIX}-${INCOME}-job-1`,
    title: 'Job 1 (take-home pay)'
  },
  {
    type: 'number',
    key: `${PREFIX}-${INCOME}-job-2`,
    title: 'Job 2 (take-home pay)'
  },
  {
    type: 'number',
    key: `${PREFIX}-${INCOME}-self-employed`,
    title: 'Self-employment income'
  },
  {
    type: 'number',
    key: `${PREFIX}-${INCOME}-benefits`,
    title: 'Benefits or other income'
  }
];

const expensesQuestions = [
  {
    type: 'number',
    key: `${PREFIX}-${EXPENSE}-mortgage`,
    title: 'Estimated mortgage or rent payment'
  },
  {
    type: 'number',
    key: `${PREFIX}-${EXPENSE}-maintenance`,
    title: 'Maintenance, repairs, or other'
  },
  {
    type: 'number',
    key: `${PREFIX}-${EXPENSE}-utilities`,
    title: 'Utilities (i.e. electiricity, gas, water)'
  },
  {
    type: 'number',
    key: `${PREFIX}-${EXPENSE}-telephone`,
    title: 'Telephone'
  },
  {
    type: 'number',
    key: `${PREFIX}-${EXPENSE}-internet`,
    title: 'Internet'
  },
  {
    type: 'number',
    key: `${PREFIX}-${EXPENSE}-tv`,
    title: 'Cable TV, dish, etc.'
  },
  {
    type: 'number',
    key: `${PREFIX}-insurance`,
    title: 'Insurance and doctor visits'
  },
  {
    type: 'number',
    key: `${PREFIX}-${EXPENSE}-medicine`,
    title: 'Medicine (prescriptions and other)'
  },
  {
    type: 'number',
    key: `${PREFIX}-${EXPENSE}-transit`,
    title: 'Mass transit costs'
  },
  {
    type: 'number',
    key: `${PREFIX}-${EXPENSE}-car`,
    title: 'Car payments, insurance, maintenance'
  },
  {
    type: 'number',
    key: `${PREFIX}-${EXPENSE}-taxi`,
    title: 'Taxis and ride share apps'
  },
  {
    type: 'number',
    key: `${PREFIX}-${EXPENSE}-credit-card`,
    title: 'Payments on credit card balances'
  },
  {
    type: 'number',
    key: `${PREFIX}-${EXPENSE}-loan`,
    title: 'Loan payments (i.e. student loans)'
  },
  {
    type: 'number',
    key: `${PREFIX}-${EXPENSE}-groceries`,
    title: 'Groceries'
  },
  {
    type: 'number',
    key: `${PREFIX}-${EXPENSE}-household`,
    title: 'Household supplies'
  },
  {
    type: 'number',
    key: `${PREFIX}-${EXPENSE}-meals`,
    title: 'Meals out'
  },
  {
    type: 'number',
    key: `${PREFIX}-${EXPENSE}-childcare`,
    title: 'Childcare'
  },
  {
    type: 'number',
    key: `${PREFIX}-${EXPENSE}-tuition`,
    title: 'Tuition'
  },
  {
    type: 'number',
    key: `${PREFIX}-${EXPENSE}-supplies`,
    title: 'Supplies, lessons and allowances'
  },
  {
    type: 'number',
    key: `${PREFIX}-${EXPENSE}-entertainment`,
    title: 'Entertainment and hobbies'
  },
  {
    type: 'number',
    key: `${PREFIX}-${EXPENSE}-grooming`,
    title: 'Personal grooming (salon, toiletries etc)'
  },
  {
    type: 'number',
    key: `${PREFIX}-${EXPENSE}-clothes`,
    title: 'Clothing and shoes'
  },
  {
    type: 'number',
    key: `${PREFIX}-${EXPENSE}-gym`,
    title: 'Gym'
  },
  {
    type: 'number',
    key: `${PREFIX}-${EXPENSE}-laundry`,
    title: 'Laundry'
  },
  {
    type: 'number',
    key: `${PREFIX}-${EXPENSE}-travel`,
    title: 'Travel/vacation'
  },
  {
    type: 'number',
    key: `${PREFIX}-${EXPENSE}-donations`,
    title: 'Donations'
  },
  {
    type: 'number',
    key: `${PREFIX}-${EXPENSE}-pets`,
    title: 'Pets'
  },
  {
    type: 'number',
    key: `${PREFIX}-${EXPENSE}-subscriptions`,
    title: 'Subscriptions'
  }
];

export default {
  toolNumber: 1,
  displayToolNumber: 'Tool 1',
  title: 'Income & Expenses Worksheet',
  url: '/en/income-and-expenses',
  currentIncome: 'CURRENT INCOME',
  currentIncomeDesc: 'First, understanding the total take-home pay (after taxes) for all household members contributing to the budget is an important step to creating a realistic budget.',
  income: 'Income',
  incomeQuestions,
  totalIncome: 'Total Monthly Income',
  currentAmount: 'Current Monthly Amount',
  currentExpenses: 'CURRENT EXPENSES',
  currentExpensesDesc: 'In this calculator, enter realistic estimates of your monthly spending for each category. These should be amounts that you feel comfrotable living with going forward.\n\nIf you have a hard time calculating your monthly expenses, click to some strategies that have worked for other clients to track their spending.',
  strategiesTitle: 'Strategies to Track Your Spending',
  strategies: [
    'Keep a spending log for 1 week. Keep a list of everything you buy during one week. At the end of the week, add up expenses in different categories.',
    'Track your spending with online banking. If you use a bank account, call or visit your bank to sign up for online banking. Most online bank apps show your spending in different categories. Or...',
    'Read your bank statements line by line. Look closely at your last two months of bank statements, assign each expense to a category, then add your total spending in each category. Or...',
    'Use the envelope method. Label a set of envelopes with different budget categories and amounts (housing, food, utility bills, etc). When you get paid, put the amount of cash in each envelope that you want to spend in that category during a week or month. Or...',
    'Budget with apps. There are many free budgeting apps that help track your spending across multiple accounts and cards. You can set your own budget, and the app will send you reminders when you’re close to your limit. Remember to keep your personal account information secure when using financial apps and banking websites'
  ],
  expenses: 'Expenses',
  total: 'Total',
  expensesQuestions,
  totalExpenses: 'Total Monthly Expenses',
  questions: expensesQuestions.length + incomeQuestions.length,
  currentBudget: 'CURRENT MONTHLY BUDGET',
  currentBudgetDesc: "This calculation shows how much you have left over each month with the housing and living expenses you included. If you don't have a lot left over, you may want to talk to a housing counselor about options to reduce expenses, manage debt, or further discuss what housing amount is affordable for you.",
  leftOver: 'How Much is Left Over',
  numberOfQuestions: 32
};
