import React from 'react';
import Locale from '../locale/en/noMatch';

const NoMatch = () => (
  <div className="no-route">
    <h2>{Locale.title}</h2>
    <p>{Locale.message}</p>
  </div>
);

export default NoMatch;
