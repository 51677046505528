import React from 'react';
import SocialMedia from './SocialMedia';
import GlobalLocale from '../locale/en/global';
import PageLocale from '../locale/en/footer';

const Footer = () => (
  <footer>
    <div className="links">
      <a href="https://cnycn.org/privacy-policy/">{GlobalLocale.privacyCookies}</a>
      <a href="https://cnycn.org/terms/">{GlobalLocale.terms}</a>
    </div>
    <div className="contact">
      {PageLocale.content}
      <div className="phone" dangerouslySetInnerHTML={{ __html: PageLocale.phone }} />
    </div>
    <div className="links">
      <SocialMedia />
    </div>
  </footer>
);

export default Footer;
