import { useState, useEffect } from 'react';
import axios from 'axios';

const useAxios = (data, tool, passedHttpVerb) => {
  const [returnedAnswers, setReturnedAnswers] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [httpVerb, setHttpVerb] = useState(passedHttpVerb);

  const csrfToken = document.querySelector('[name=csrf-token]').content;
  axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

  useEffect(() => {
    const processData = async () => {
      setIsLoading(true);
      setHasError(false);
      const response = await axios({
        method: httpVerb,
        url: '/answers',
        params: {
          tool,
          data
        }
      });
      setReturnedAnswers(response.data);
      setIsLoading(false);
      setHttpVerb(null);
    };

    processData().catch(() => {
      setIsLoading(false);
      setHasError(true);
    });
  }, [httpVerb]);

  return [{ returnedAnswers, isLoading, hasError }, setHttpVerb];
};

export default useAxios;
