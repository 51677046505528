import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import { createBrowserHistory as createHistory } from 'history';
import { ToolkitContext } from '../context';
import ScrollToTop from './ScrollToTop';
import AppRouter from './AppRouter';
import NavBar from './NavBar';
import Footer from './Footer';
import '../styles/application.scss';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [name, setName] = useState('Guest');
  const [isCheckupComplete, setIsCheckupComplete] = useState(false);
  const [checkupResults, setCheckupResults] = useState({});
  ReactGA.initialize('UA-151050066-1');

  const history = createHistory();

  history.listen((location) => {
    ReactGA.pageview(location.pathname);
  });

  useEffect(() => {
    axios.get('/logged-in').then((response) => {
      setIsLoggedIn(response.data.user);
      setName(response.data.name);
      setIsCheckupComplete(response.data.checkup);
      setCheckupResults(response.data.checkupResults);
    });
  }, []);

  return (
    <ToolkitContext.Provider value={{
      isLoggedIn,
      name,
      isCheckupComplete,
      checkupResults,
      setCheckupResults,
      setIsCheckupComplete
    }}
    >
      <BrowserRouter>
        <ScrollToTop />
        <NavBar />
        <AppRouter />
        <Footer />
      </BrowserRouter>
    </ToolkitContext.Provider>
  );
};

export default App;
