import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import ToolMetadata from './ToolMetadata';
import Locale from '../locale/en/toolList';
import { ToolkitContext } from '../context';

const ToolList = () => {
  const { isLoggedIn } = useContext(ToolkitContext);
  return (
    <div className="tool-list">
      <h2 className="tool-list--title">{Locale.title}</h2>
      <ToolMetadata
        tool="incomeAndExpenses"
      />
      <ToolMetadata
        tool="ideasToBoostIncome"
      />
      <ToolMetadata
        tool="ideasToReduceExpenses"
      />
      <ToolMetadata
        tool="smartSavingsGoals"
      />
      <ToolMetadata
        tool="manageCreditAndDebt"
      />
      <ToolMetadata
        tool="creditReportReview"
      />
      <ToolMetadata
        tool="debtManagementWorksheet"
      />
      {!isLoggedIn && (
        <Link
          to="/en/login"
        >
          {Locale.login}
        </Link>
      )}
    </div>
  );
};

export default ToolList;
