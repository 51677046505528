import React from 'react';
import PropTypes from 'prop-types';
import CheckmarkLg from '../images/checkmark-lg.svg';
import CheckmarkSm from '../images/checkmark-sm.svg';

const ChecklistItem = ({ text, miniCheckmark }) => {
  const iconSrc = miniCheckmark ? CheckmarkSm : CheckmarkLg;

  return (
    <div className="checklist-item">
      <img src={iconSrc} alt="checkmark" />
      <span>{text}</span>
    </div>
  );
};

ChecklistItem.propTypes = {
  text: PropTypes.string.isRequired,
  miniCheckmark: PropTypes.bool.isRequired
};

export default ChecklistItem;
