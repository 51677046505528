import React from 'react';
import PropTypes from 'prop-types';
import Locale from '../locale/en/incomeAndExpenses';
import { formatCurrency } from '../helpers';

const IncomeAndExpensesTotal = ({ income, expenses }) => (
  <div id="income-and-expenses-total">
    <h2 className="budget-title">{Locale.currentBudget}</h2>
    <div className="budget-desc">{Locale.currentBudgetDesc}</div>
    <ul>
      <div className="number-input">
        <div className="item-wrapper">
          <div className="item-question">
            {Locale.totalIncome}
          </div>
          <div className="item-answer total">
            {formatCurrency(income)}
          </div>
        </div>
      </div>
      <div className="number-input">
        <div className="item-wrapper">
          <div className="item-question">
            {Locale.totalExpenses}
          </div>
          <div className="item-answer total">
            {formatCurrency(expenses)}
          </div>
        </div>
      </div>
      <div className="number-input">
        <div className="item-wrapper">
          <div className="item-question">
            {Locale.leftOver}
          </div>
          <div className="item-answer total">
            {formatCurrency(income - expenses)}
          </div>
        </div>
      </div>
    </ul>
  </div>
);

IncomeAndExpensesTotal.propTypes = {
  income: PropTypes.number,
  expenses: PropTypes.number
};

IncomeAndExpensesTotal.defaultProps = {
  income: 0,
  expenses: 0
};

export default IncomeAndExpensesTotal;
