const PREFIX = 'ideas-income';

const questions = [
  {
    type: 'number',
    key: `${PREFIX}-odd-jobs`,
    title: 'Do odd jobs, errands, or gigs, such as childcare, tutoring, painting and repair work, or dog walking. Talk to a friend or family member to brainstorm ways you can use your skills to earn money.'
  },
  {
    type: 'number',
    key: `${PREFIX}-raise`,
    title: 'Seek a raise or additional hours at your current job. Practice salary negotiation with a counselor or friend.'
  },
  {
    type: 'number',
    key: `${PREFIX}-rental`,
    title: 'Bring in rental income. Rent a room in your home or charge an adult family member for rent.'
  },
  {
    type: 'number',
    key: `${PREFIX}-part-time`,
    title: 'Get a part-time or seasonal job.',
    description: 'If you have time, taking on a part-time or temp job to bring in extra income could help get you through your current crisis. For example, you may be able to find a seasonal job, such as working in a department store during holidays or working for the Board of Elections.'
  },
  {
    type: 'number',
    key: `${PREFIX}-invest`,
    title: 'Invest in your long-term earnings potential.',
    description: 'Visit a nearby Workforce1 Career Center to get one-on-one job and career counseling, technical and educational training, and referrals to other providers. Visit their website or call 311 for more information.'
  },
  {
    type: 'number',
    key: `${PREFIX}-sbs`,
    title: 'Seek out small business support services.',
    description: 'If you run a small business, learn about ways to boost your profit with courses, financing assistance, recruitment services, pro-bono legal advising, and many other service from the NYC Department of Small Business Services. To learn more, visit their website or call 311.'
  }
];

const userGeneratedQuestions = [
  {
    type: 'text-area',
    key: `${PREFIX}-other-textarea`,
    title: 'Other way(s) to boost my income:',
    placeholder: 'Add your ideas'
  },
  {
    type: 'number',
    key: `${PREFIX}-other-number`,
    title: ''
  }
];

const userGeneratedText = [
  {
    type: 'text',
    key: `${PREFIX}-first-text`,
    title: '',
    placeholder: ''
  },
  {
    type: 'text',
    key: `${PREFIX}-second-textarea`,
    title: '',
    placeholder: ''
  }
];

const getNumberOfQuestions = () => (
  questions.length +
  userGeneratedQuestions.length +
  userGeneratedText.length
);

export default {
  toolNumber: 2,
  displayToolNumber: 'Tool 2',
  title: 'Ideas To Boost Your Income',
  url: '/en/ideas-to-boost-income',
  savings: 'Potential Savings',
  description: 'Many homeowners have successfully used these strategies to boost their income when they are in a financial pinch.',
  questions,
  userGeneratedQuestions,
  userGeneratedText,
  writtenIdeas: 'What steps will you take to get started? Write down 2 ideas to improve your financial health:',
  estimate: 'Estimated potential monthly income increase',
  share: 'Share this list with someone who knows you well, such as your partner, another family member or close friend. They may have more ideas that make sense for you.',
  numberOfQuestions: getNumberOfQuestions()
};
