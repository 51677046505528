import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ToolkitContext } from '../context';
import Logout from './Logout';
import Locale from '../locale/en/login';

const Login = ({
  width,
  height,
  alignItems,
  fontSize,
  padding
}) => {
  const { isLoggedIn } = useContext(ToolkitContext);
  const loginStyles = {
    width,
    height,
    alignItems
  };

  const headerStyles = {
    fontSize,
    padding
  };

  if (isLoggedIn) {
    return (
      <Logout />
    );
  }

  let csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  
  return (
    <div className="login" style={loginStyles}>
      <h4 style={headerStyles}>{Locale.login}</h4>
      <form>
        <input type="hidden"/>
        <input id="inactive" type="submit" value={Locale.facebook} />
      </form>
      <form action="/auth/twitter">
        <input type="hidden" name="authenticity_token" value={csrfToken} />
        <input formMethod='post' type="submit" value={Locale.twitter} />
      </form>
      <form action="/auth/google_oauth2">
        <input type="hidden" name="authenticity_token" value={csrfToken} />
        <input formMethod='post' type="submit" value={Locale.gmail} />
      </form>
      <p>{Locale.loginSubheader}</p>
    </div>
  );
};

Login.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  alignItems: PropTypes.string.isRequired,
  fontSize: PropTypes.string.isRequired,
  padding: PropTypes.string.isRequired
};

export default Login;
