import React from 'react';
import PropTypes from 'prop-types';

const Subheader = ({ text: { title, description } }) => (
  <div className="subheader">
    {title ? <h2>{title}</h2> : null}
    <div className="description">{description}</div>
  </div>
);

Subheader.propTypes = {
  text: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string.isRequired
  }).isRequired
};

export default Subheader;
