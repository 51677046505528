export default {
  login: 'Login',
  loginSubheader: 'Log in with one of the providers above',
  facebook: 'Facebook',
  twitter: 'Twitter',
  gmail: 'Gmail',
  email: 'Email Address',
  password: 'Password',
  forgot: 'Forgot password?',
  logout: 'Log Out'
};
