import React from 'react';
import PropTypes from 'prop-types';
import Locale from '../locale/en/quiz';

const RadioInput = ({
  question,
  id,
  setAnswers,
  answer,
  singleButton
}) => {
  const handleClick = (questionId, value) => {
    setAnswers(previousAnswers => ({
      ...previousAnswers, [questionId]: value
    }));
  };

  const renderSingleButton = () => (
    <div className="radio-input-single">
      <div className="item-question" dangerouslySetInnerHTML={{ __html: question }} />
      <div className="single-answer">
        <input
          id={`single-${id}`}
          type="radio"
          name={id}
          value
          defaultChecked={answer === true}
          onClick={() => handleClick(id, !answer)}
        />
        <label htmlFor={`single-${id}`} />
      </div>
    </div>
  );

  const renderMultipleButtons = () => (
    <div className="radio-input">
      <div className="item-question" dangerouslySetInnerHTML={{ __html: question }} />
      <div className="item-answer">
        <input
          id={`yes-${id}`}
          type="radio"
          name={id}
          value
          defaultChecked={answer === true}
          onClick={() => handleClick(id, true)}
        />
        <label htmlFor={`yes-${id}`}>
          {Locale.yes}
        </label>
        <input
          id={`no-${id}`}
          type="radio"
          name={id}
          value={false}
          defaultChecked={answer === false}
          onClick={() => handleClick(id, false)}
        />
        <label htmlFor={`no-${id}`}>
          {Locale.no}
        </label>
      </div>
    </div>
  );

  return (
    <div>
      {singleButton ? renderSingleButton() : renderMultipleButtons()}
    </div>
  );
};

RadioInput.propTypes = {
  question: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  setAnswers: PropTypes.func.isRequired,
  answer: PropTypes.bool,
  singleButton: PropTypes.bool
};

RadioInput.defaultProps = {
  answer: null,
  singleButton: false
};

export default RadioInput;
