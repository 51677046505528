import React from 'react';
import PropTypes from 'prop-types';

const SingleCheckboxInput = ({
  question,
  id,
  setAnswers,
  answer,
  choices
}) => {
  const handleClick = (questionKey, choice) => {
    setAnswers(previousAnswers => ({
      ...previousAnswers, [questionKey]: choice
    }));
  };

  return (
    <div className="checkbox-input">
      <div className="item-question">{question}</div>
      <div className="choices">
        {choices.map(choice => (
          <label
            htmlFor={choice.key}
            key={choice.key}
            className="choice"
            onClick={() => handleClick(id, choice.key)}
          >
            {choice.text}
            <input
              type="checkbox"
              name={choice.key}
              checked={choice.key === answer}
            />
            <span className="checkbox" />
          </label>
        ))}
      </div>
    </div>
  );
};

SingleCheckboxInput.propTypes = {
  question: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  answer: PropTypes.string,
  setAnswers: PropTypes.func.isRequired,
  choices: PropTypes.array.isRequired
};

SingleCheckboxInput.defaultProps = {
  answer: ''
};

export default SingleCheckboxInput;
