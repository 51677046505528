import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import GlobalLocale from '../locale/en/global';
import Locale from '../locale/en/navBar';
import Logout from './Logout';
import User from './User';
import Close from '../images/white-exit.svg';
import Hamburger from '../images/hamburger.png';
import Logo from '../images/cny-logo-nav.svg';
import { ToolkitContext } from '../context';

const NavBar = () => {
  const { isLoggedIn, name, isCheckupComplete } = useContext(ToolkitContext);
  const [showNavItems, setShowNavItems] = useState(false);

  const renderMobileLinks = () => {
    let menuName;
    let dashboardCheckup;
    let loginLogout;
    if (showNavItems) {
      menuName = (
        <div className="name">
          {Locale.hello}
          <User name={name} />
          !
        </div>
      );
      dashboardCheckup = isCheckupComplete ?
        (
          <div className="link">
            <Link
              to="/en/dashboard"
              onClick={() => setShowNavItems(prevState => !prevState)}
            >
              {Locale.dashboard}
            </Link>
          </div>
        ) :
        (
          <div className="link">
            <Link
              to="/en/checkup"
              onClick={() => setShowNavItems(prevState => !prevState)}
            >
              {Locale.takeCheckup}
            </Link>
          </div>
        );
      loginLogout = isLoggedIn ? (
        <div className="link">
          <Logout />
        </div>
      ) : (
        <div className="link">
          <Link
            to="/en/login"
            onClick={() => setShowNavItems(prevState => !prevState)}
          >
            {Locale.logIn}
          </Link>
        </div>
      );
    }
    return (
      <>
        {menuName}
        {dashboardCheckup}
        {loginLogout}
      </>
    );
  };

  return (
    <nav>
      <div className="left-aligned">
        <Link to="/" className="title">
          <img className="logo" src={Logo} alt="CNYCN logo" />
        </Link>
        <p>{GlobalLocale.siteName}</p>
      </div>
      <div className="links">
        <div className="name">
          {Locale.hello}
          <User name={name} />
          !
        </div>
        {isCheckupComplete ?
          (
            <div className="link">
              <Link to="/en/dashboard">
                {Locale.dashboard}
              </Link>
            </div>
          ) :
          (
            <div className="link">
              <Link to="/en/checkup">
                {Locale.takeCheckup}
              </Link>
            </div>
          )
        }
        {isLoggedIn ? (
          <div className="link">
            <Logout />
          </div>
        ) : (
          <div className="link">
            <Link to="/en/login">
              {Locale.logIn}
            </Link>
          </div>
        )
        }
      </div>
      <div className={showNavItems ? 'mobile-links show' : 'mobile-links hide'}>
        <img
          onClick={() => setShowNavItems(prevState => !prevState)}
          src={showNavItems ? Close : Hamburger}
          alt="Hamburger Menu"
        />
        {renderMobileLinks()}
      </div>
    </nav>
  );
};

export default NavBar;
