const numberFormatter = new Intl.NumberFormat(
  'en-US',
  {
    style: 'currency',
    currency: 'USD'
  }
);

export const formatCurrency = value => numberFormatter.format(value);

export const currencyToText = value => value.replace(/[$,]/g, '');

export const isCurrency = (value) => {
  if (Number.isNaN(Number.parseFloat(currencyToText(value)))) { return false; }
  if (value.match(/[A-Za-z]/)) { return false; }
  return true;
};

export const getTotal = (answersObj) => {
  const values = Object.values(answersObj);
  let total = 0;

  if (values.length > 0) {
    total = values
      .map((value) => {
        let formattedValue = currencyToText(value);
        if (Number.isNaN(Number.parseFloat(formattedValue))) {
          formattedValue = 0;
        }
        return parseFloat(formattedValue, 10);
      })
      .reduce((a, b) => a + b);
  }

  return formatCurrency(total);
};

export const textToCurrency = (value) => {
  let response = value;
  response = response.replace(/\D/g, '');
  response = response.replace(/^0+/, '');
  if (response.length === 1) {
    response = `$0.0${response}`;
  } else if (response.length === 2) {
    response = `$0.${response}`;
  } else if (response.length > 2) {
    const valueArr = response.split('');
    valueArr.splice(-2, 0, '.');
    response = valueArr.join('');
    response = formatCurrency(response);
  }
  return response;
};
