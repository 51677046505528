export default {
  header: 'Your Financial Health Check-Up',
  subheader: {
    description: 'Let’s get started! The toolkit includes information about the following topics; we are also able to assist you with a referral to a housing or legal provider.'
  },
  checklistItems: [
    {
      key: 'financial',
      text: 'Setting financial goals and making an Action Plan to reach them'
    },
    {
      key: 'income',
      text: 'Increasing your income (through career resources, small business training)'
    },
    {
      key: 'budget',
      text: 'Budgeting and cost-cutting ideas'
    },
    {
      key: 'debt',
      text: 'Making a plan to pay down debt'
    },
    {
      key: 'credit',
      text: 'Repairing your credit score'
    }
  ]
};
