import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Locale from '../locale/en/quizResults';
import Checklist from './Checklist';
import { ToolkitContext } from '../context';

const resultTypes = [Locale.budget, Locale.savings, Locale.debt];

const QuizResults = ({ suggestedTools }) => {
  const { isLoggedIn } = useContext(ToolkitContext);

  const results = () => {
    let suggestedToolsArr = suggestedTools;
    // Show all text by default
    if (suggestedToolsArr.length === 0) {
      suggestedToolsArr = [1, 2, 3];
    }
    return (resultTypes.map((result, idx) => {
      if (suggestedToolsArr.includes(idx + 1)) {
        return (
          <div className="quiz-result" key={result.description}>
            <div className="quiz-result--description">{result.description}</div>
            <Checklist items={result.checklist} miniCheckmark />
          </div>
        );
      }
      return null;
    }));
  };

  return (
    <div className="quiz-results">
      <h2 className="quiz-results--title">{Locale.title}</h2>
      {results()}
      {!isLoggedIn &&
        <div className="if-close-browser" dangerouslySetInnerHTML={{ __html: Locale.ifCloseBrowser }} />
      }
      <div className="results-buttons">
        <Link
          to="/en/checkup"
          className="ghost"
        >
          {Locale.retake}
        </Link>
      </div>
    </div>
  );
};

QuizResults.propTypes = {
  suggestedTools: PropTypes.arrayOf(PropTypes.number)
};

QuizResults.defaultProps = {
  suggestedTools: [1, 2, 3]
};

export default QuizResults;
