import React from 'react';
import Facebook from '../images/facebook.svg';
import Twitter from '../images/twitter.svg';
import Email from '../images/email.svg';

const SocialMedia = () => (
  <div className="social-media">
    <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Ftoolkit.cnycn.org%2F&amp;src=sdkpreparse">
      <img src={Facebook} alt="Facebook logo" />
    </a>
    <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/intent/tweet?text=Does%20your%20financial%20health%20need%20a%20check%20up%3F%20Try%20the%20Financial%20Toolkit%20by%20%40CNYCN%20for%20tips%20to%20budget%20better%2C%20boost%20your%20credit%20score%2C%20and%20more%21&url=https://toolkit.cnycn.org">
      <img src={Twitter} alt="Twitter logo" />
    </a>
    <a href="mailto:?&subject=A%20bigger%20salary%20isn%27t%20the%20only%20path%20to%20a%20secure%20future.%20Try%20the%20Financial%20Toolkit%20for%20help%20reaching%20your%20long-term%20goals&body=Check%20out%20the%20Financial%20Toolkit%20at%20https://toolkit.cnycn.org%21">
      <img src={Email} alt="Email logo" />
    </a>
  </div>
);

export default SocialMedia;
