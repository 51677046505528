const PREFIX = 'ideas-expenses';

const questions = [
  {
    key: `${PREFIX}-bills`,
    type: 'number',
    title: 'Get better deals on your regular bills.',
    description: 'When it comes to services like cable, Internet, cell phone, and insurance, you have a right to shop around. Call your service providers today and ask for a cheaper plan.'
  },
  {
    key: `${PREFIX}-needs-wants`,
    type: 'number',
    title: 'Categorize your current expenses into "needs" and "wants."',
    description: 'Identify expenses that are "wants," an item that you can do without for the time being. Rather than buying a "want" impulsively, make a commitment to yourself to save or wait before buying it.'
  },
  {
    key: `${PREFIX}-groceries`,
    type: 'number',
    title: 'Save money on groceries:',
    description: '<ul><li>Buy in bulk.<li>Look for sale items.</li><li>Avoid buying things that you don’t need.</li><li>Meal planning ahead of time can help prevent food waste.</li><li>Buying bottled beverages and drinks from coffee shops can add up. Consider making your own beverages at home.</li></ul>'
  },
  {
    key: `${PREFIX}-utilities1`,
    type: 'number',
    title: 'Make some changes to lower your utility bills:',
    description: 'Set your thermostat as high in the summer and as low in the winter as is comfortable. For example, setting your thermostat 2 degrees warmer in the summer could save you hundreds of dollars every year.'
  },
  {
    key: `${PREFIX}-utilities2`,
    type: 'number',
    title: 'Make some changes to lower your utility bills:',
    description: 'Visit <a href="https://www.coned.com/en/save-money/estimate-your-energy-usage">ConEd\'s Home Energy calculator</a> for specific tips that could save you hundreds of dollars on your home energy.'
  },
  {
    key: `${PREFIX}-utilities3`,
    type: 'number',
    title: 'Make some changes to lower your utility bills:',
    description: 'Check to see if you qualify for an energy assistance program. Call 311 or visit <a href="https://www.nyc.gov">nyc.gov</a> for more information.'
  },
  {
    key: `${PREFIX}-subscriptions`,
    type: 'number',
    title: 'Cancel subscriptions and services that you aren’t using or use infrequently, such as',
    description: '<ul><li>gym memberships</li><li>online music</li><li>newspapers and magazines</li><li>video services</li></ul>Check your bank statements to see if there are recurring charges you have forgotten about.'
  },
  {
    key: `${PREFIX}-habits`,
    type: 'number',
    title: 'Talk with your family or other members of your household and agree to establish new habits to reduce spending. Review the ideas in this handout with them, brainstorm other ways to cut expenses, and create an action plan together.'
  }
];

const userGeneratedQuestions = [
  {
    type: 'text-area',
    key: `${PREFIX}-other-textarea`,
    question: 'Other way(s) to cut expenses:',
    placeholder: 'Add your ideas'
  },
  {
    type: 'number',
    key: `${PREFIX}-other-number`,
    question: ''
  }
];

export default {
  toolNumber: 3,
  displayToolNumber: 'Tool 3',
  title: 'Ideas To Reduce Expenses',
  url: '/en/ideas-to-reduce-expenses',
  savings: 'Potential Savings',
  description: 'Many homeowners have successfully used these strategies to cut expenses when they are in a financial pinch.',
  questions,
  userGeneratedQuestions,
  estimate: 'Estimated monthly expenses cuts',
  share: 'Share this list with other members of your household. Having a family conversation can help turn ideas into reality. Your loved ones may have creative ideas that aren’t on this list.',
  numberOfQuestions: questions.length + userGeneratedQuestions.length
};
