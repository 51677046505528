import React, { useState } from 'react';
import PropTypes from 'prop-types';
import GlobalLocale from '../locale/en/global';

const ToggleDateInput = ({
  question,
  id,
  setAnswers,
  answer
}) => {
  const [inputVisibility, setInputVisibility] = useState(!!answer);

  // TODO figure out why event.persist() is needed and why it works
  const handleChange = (event) => {
    event.persist();
    setAnswers(previousAnswers => ({
      ...previousAnswers, [id]: event.target.value
    }));
  };

  const handleClick = () => {
    if (answer) {
      setAnswers(previousAnswers => ({
        ...previousAnswers, [id]: null
      }));
    }
    setInputVisibility(prevState => !prevState);
  };
  // TODO return min and max date dynamically from Date instance
  return (
    <div className="date-input toggleable">
      <div className="item-wrapper">
        <div className="item-question" dangerouslySetInnerHTML={{ __html: question }} />
        <div className="item-answer">
          <div
            className={
              `toggleable-item
              ${answer || inputVisibility ? '' : 'hide'}`
            }
          >
            <div>{GlobalLocale.selectDate}</div>
            <input
              type="date"
              id={id}
              value={answer || ''}
              onChange={handleChange}
              min="2018-01-01"
              max="2020-12-31"
            />
          </div>
          <div className="radio-input-single">
            <div className="single-answer">
              <input
                id={`single-${id}`}
                type="radio"
                name={id}
                checked={!!answer}
                onClick={handleClick}
              />
              <label htmlFor={`single-${id}`} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ToggleDateInput.propTypes = {
  question: PropTypes.string.isRequired,
  setAnswers: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  answer: PropTypes.string
};

ToggleDateInput.defaultProps = {
  answer: null
};

export default ToggleDateInput;
