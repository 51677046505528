import React from 'react';
import PropTypes from 'prop-types';
import { textToCurrency } from '../helpers';

const NumberInput = ({
  question,
  description,
  setAnswers,
  answer,
  id
}) => {
  const handleChange = (event) => {
    event.persist();
    setAnswers(previousAnswers => ({
      ...previousAnswers, [id]: textToCurrency(event.target.value)
    }));
  };

  const renderDescription = (
    description && (
      <div
        className="description"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    )
  );

  return (
    <div className="number-input">
      <div className="item-wrapper">
        <div className="item-question">{question}</div>
        {renderDescription}
        <div className="item-answer">
          <input
            type="text"
            value={answer}
            onChange={handleChange}
            placeholder="$0.00"
          />
        </div>
      </div>
    </div>
  );
};

NumberInput.propTypes = {
  question: PropTypes.string.isRequired,
  description: PropTypes.string,
  setAnswers: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  answer: PropTypes.string
};

NumberInput.defaultProps = {
  answer: '',
  description: null
};

export default NumberInput;
