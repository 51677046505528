import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import Locale from '../locale/en/dashboard';
import Header from './Header';
import ToolBanner from './ToolBanner';
import ToolList from './ToolList';
import QuizResults from './QuizResults';
import ContactUs from './ContactUs';
import { ToolkitContext } from '../context';

const Dashboard = () => {
  document.title = 'Dashboard | Financial Toolkit';
  const location = useLocation();
  const [suggestedTools, setSuggestedTools] = useState([]);

  const {
    isLoggedIn,
    checkupResults,
    setCheckupResults,
    setIsCheckupComplete
  } = useContext(ToolkitContext);

  // Set checkupResults at the App level from quiz params.
  // Otherwise they are set by DB query from context at the App level.
  useEffect(() => {
    if (!isLoggedIn) {
      setCheckupResults(location.checkupResults);
      setIsCheckupComplete(true);
    }

    if (checkupResults) {
      // If no to number 1
      const qualifiesForOption1 = () => checkupResults['past-month'] === false;

      // If yes to number 1, no to number 4 and no to having $3000 in savings in number 2
      const qualifiesForOption2 = () => {
        const { emergency } = checkupResults;
        if (emergency) {
          const parsedEmergency = isLoggedIn ? JSON.parse(emergency) : emergency;
          return (
            checkupResults['past-month'] &&
            checkupResults['automatic-deposit'] === false &&
            !parsedEmergency.includes('savings')
          );
        }
        return (
          checkupResults['past-month'] &&
          checkupResults['automatic-deposit'] === false
        );
      };

      // If yes to one or more on number 3
      const qualifiesForOption3 = () => {
        const chooseAll = checkupResults['choose-all'];
        if (chooseAll) {
          const parsedChooseAll = isLoggedIn ? JSON.parse(chooseAll) : chooseAll;
          return parsedChooseAll.length > 0;
        }
        return false;
      };

      const handleCheckupResults = () => {
        const recommendedToolList = [];
        if (qualifiesForOption1()) { recommendedToolList.push(1); }
        if (qualifiesForOption2()) { recommendedToolList.push(2); }
        if (qualifiesForOption3()) { recommendedToolList.push(3); }
        setSuggestedTools(recommendedToolList);
      };
      handleCheckupResults();
    }
  }, [
    isLoggedIn,
    checkupResults,
    setCheckupResults,
    setIsCheckupComplete,
    location.checkupResults
  ]);

  return (
    <div className="dashboard">
      <Header text={Locale.header} />
      <ToolBanner
        suggestedTools={suggestedTools}
      />
      <div className="tools-and-results">
        <ToolList />
        <QuizResults
          suggestedTools={suggestedTools}
        />
      </div>
      <ContactUs />
    </div>
  );
};

export default Dashboard;
