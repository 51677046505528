export default {
  yes: 'YES',
  no: 'NO',
  submit: 'SUBMIT',
  toolNumber: 0,
  questions: [
    {
      key: 'past-month',
      number: 'Question 1',
      type: 'radio',
      title: "Over the past month, would you say your family's spending on living expenses was less than your total income?"
    },
    {
      key: 'emergency',
      number: 'Question 2',
      type: 'multiple',
      title: 'If you needed $3,000 for an emergency, where would you get it? Check all that apply.',
      choices: [
        {
          key: 'savings',
          text: 'I have at least $3,000 in savings'
        },
        {
          key: 'cash',
          text: 'I have other assets I could sell or cash out an asset (like stocks or a retirement fund).'
        },
        {
          key: 'borrow',
          text: 'I could borrow the money from family or friends.'
        },
        {
          key: 'loan',
          text: 'I would get a loan or credit card advance.'
        },
        {
          key: 'unable',
          text: 'I would not be able to get the money.'
        }
      ]
    },
    {
      key: 'choose-all',
      number: 'Question 3',
      type: 'multiple',
      title: 'Do any of the following apply to you? Check all that apply.',
      choices: [
        {
          key: 'credit',
          text: 'I have more than $2,000 in credit card debt.'
        },
        {
          key: 'student',
          text: 'I have unpaid student loans.'
        },
        {
          key: 'secured-debt',
          text: 'I am behind on secured debts (like car payments or a mortgage).'
        },
        {
          key: 'other',
          text: 'I have other outstanding debt or judgments (medical or other)'
        }
      ]
    },
    {
      key: 'automatic-deposit',
      number: 'Question 4',
      type: 'radio',
      title: 'Do you currently make regular savings deposits to achieve a financial goal or build an emergency fund?'
    }
  ]
};
