import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Locale from '../locale/en/debtManagementWorksheet';
import GlobalLocale from '../locale/en/global';
import ToolHeader from './ToolHeader';
import InputElementRouter from './InputElementRouter';
import ErrorPage from './ErrorPage';
import Loading from './Loading';
import useAxios from '../hooks';

const DebtManagementWorksheet = ({ isLoggedIn }) => {
  document.title = Locale.title;
  const [answers, setAnswers] = useState({});

  const [{ returnedAnswers, isLoading, hasError }, refetchWithHttpVerb] = useAxios(
    answers,
    Locale.toolNumber,
    'get'
  );

  const postData = () => {
    refetchWithHttpVerb('post');
  };

  const deleteData = () => {
    refetchWithHttpVerb('delete');
  };

  useEffect(() => {
    setAnswers(returnedAnswers);
  }, [returnedAnswers]);

  if (hasError) { return <ErrorPage />; }
  if (isLoading) { return <Loading />; }

  return (
    <div className="debt-management">
      <div className="wrapper">
        <ToolHeader
          number={Locale.displayToolNumber}
          title={Locale.title}
          answers={answers}
          questions={Locale.numberOfQuestions}
          postData={postData}
          deleteData={deleteData}
        />
        <div className="tool-description">{Locale.description}</div>
      </div>
      <div className="debt-container">
        <div className="debt-wrapper">
          {Locale.questions.map(question => (
            <div className="question" key={question.key}>
              <InputElementRouter
                question={question}
                setAnswers={setAnswers}
                answers={answers}
              />
            </div>
          ))}
        </div>
        {isLoggedIn && (
          <div className="save-all">
            <button
              type="button"
              onClick={postData}
            >
              {GlobalLocale.saveAll}
            </button>
          </div>
        )}
      </div>
      <div className="method-container">
        <p>{Locale.foreclosureDescription}</p>
        <h2>{Locale.rateHeader}</h2>
        <p>{Locale.rateDescription}</p>
        <div className="pro-con">
          <p>{Locale.pro}</p>
          {Locale.ratePro}
        </div>
        <div className="pro-con">
          <p>{Locale.con}</p>
          {Locale.rateCon}
        </div>
        <h2>{Locale.snowballHeader}</h2>
        <p>{Locale.snowballDescription}</p>
        <div className="pro-con">
          <p>{Locale.pro}</p>
          {Locale.snowballPro}
        </div>
        <div className="pro-con">
          <p>{Locale.con}</p>
          {Locale.snowballCon}
        </div>
        <p>{Locale.note}</p>
        <div className="next">
          <Link
            className="small ghost"
            to="/en/income-and-expenses"
          >
            {GlobalLocale.nextTool}
          </Link>
          <button
            type="button"
            onClick={window.print}
          >
            {GlobalLocale.printAll}
          </button>
        </div>
      </div>
    </div>
  );
};

DebtManagementWorksheet.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired
};


export default DebtManagementWorksheet;
