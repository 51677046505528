import React, { useState } from 'react';
import ArrowDown from '../images/arrow-down.svg';
import ArrowUp from '../images/arrow-up.svg';
import Locale from '../locale/en/incomeAndExpenses';

const StrategiesDropDown = () => {
  const [show, setShow] = useState(false);
  const arrow = show ? ArrowUp : ArrowDown;

  return (
    <div className="strategies-drop-down">
      <div className="strategies-bar" onClick={() => setShow(!show)}>
        <span className="strategies-title">{Locale.strategiesTitle}</span>
        <img
          alt="control arrow"
          src={arrow}
        />
      </div>
      <div className="strategies-list">
        <ul className={show ? 'show' : 'hide'}>
          {Locale.strategies.map(
            // eslint-disable-next-line react/no-array-index-key
            text => (
              <li key={text}>{text}</li>
            )
          )}
        </ul>
      </div>
    </div>
  );
};

export default StrategiesDropDown;
