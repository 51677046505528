import React from 'react';
import PropTypes from 'prop-types';


const TextInput = ({
  question,
  placeholder,
  id,
  setAnswers,
  answer
}) => {
  // TODO figure out why event.persist() is needed and why it works
  const handleChange = (event) => {
    event.persist();
    setAnswers(previousAnswers => ({
      ...previousAnswers, [id]: event.target.value
    }));
  };

  return (
    <div className="text-input">
      <div className="item-wrapper">
        <div className="item-question">{question}</div>
        <input
          className="item-answer"
          id={id}
          type="text"
          value={answer}
          onChange={handleChange}
          placeholder={placeholder}
          minLength={4}
          maxLength={30}
          size={40}
        />
      </div>
    </div>
  );
};

TextInput.propTypes = {
  question: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  setAnswers: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  answer: PropTypes.string
};

TextInput.defaultProps = {
  placeholder: '',
  answer: ''
};

export default TextInput;
